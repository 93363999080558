import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = ["editPaActionModal"];

  connect() {
    this.editPaActionModal = new bootstrap.Modal(this.editPaActionModalTarget);
  }

  displayModal() {
    this.editPaActionModal.show();
  }
}
