import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = [
    "editPriorAuthorizationModal",
    "identifierSourceSelection",
    "identifier",
    "identifierLabel",
  ];

  connect() {
    this.editPriorAuthorizationModal = new bootstrap.Modal(
      this.editPriorAuthorizationModalTarget,
    );

    this.assignIdentifierSourceLabel();
  }

  displayModal() {
    this.editPriorAuthorizationModal.show();
  }

  assignIdentifierSourceLabel() {
    const selectedOption =
      this.identifierSourceSelectionTarget.options[
        this.identifierSourceSelectionTarget.selectedIndex
      ];

    const identifierLabelMap = {
      CoverMyMeds: "CoverMyMeds PA Key",
      Gainwell: "Authorization ID",
    };

    this.identifierLabelTarget.textContent =
      identifierLabelMap[selectedOption.value] || "Authorization ID";

    this.identifierTarget.hidden =
      this.identifierSourceSelectionTarget.selectedIndex === 0;
  }
}
