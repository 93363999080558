import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = ["shipmentModal"];
  connect() {
    this.shipmentModal = new bootstrap.Modal(this.shipmentModalTarget);
  }

  disconnect() {
    this.shipmentModal.dispose();
  }

  showShipmentModal = () => {
    this.shipmentModal.show();
  };

  hideShipmentModal = () => {
    this.shipmentModal.hide();
  };
}
