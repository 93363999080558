import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "radio",
    "submit",
    "medicareQuestion",
    "medicareButton",
    "commercialButton",
  ];

  selectOption(event) {
    this.submitTarget.disabled = !event.target.checked;
  }

  hideButtons() {
    this.medicareQuestionTarget.hidden = true;
    this.medicareButtonTarget.hidden = true;
    this.commercialButtonTarget.hidden = true;
  }
}
