import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = ["refundModal"];

  connect() {
    this.refundModal = new bootstrap.Modal(this.refundModalTarget);
  }

  disconnect() {
    this.refundModal.dispose();
  }

  showRefundModal = () => {
    this.refundModal.show();
  };

  hideRefundModal = () => {
    this.refundModal.hide();
  };
}
