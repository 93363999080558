import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = ["transferCancellationModal"];

  connect() {
    this.transferCancellationModal = new bootstrap.Modal(
      this.transferCancellationModalTarget,
    );
  }

  disconnect() {
    this.transferCancellationModal.dispose();
  }

  showTransferCancellationModal = () => {
    this.transferCancellationModal.show();
  };

  hideTransferCancellationModal = () => {
    this.transferCancellationModal.hide();
  };
}
