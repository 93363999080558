import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["termsAccepted", "submitButton"];

  connect() {
    this.setSubmitButtonDisabled();
  }

  setSubmitButtonDisabled() {
    this.submitButtonTarget.disabled = !this.termsAcceptedTarget.checked;
  }
}
