import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = ["offcanvasPartneredPharmacyIssue"];

  escapeKeyupListener = (event) => {
    if (event.key === "Escape") {
      this.hideOffcanvasPartneredPharmacyIssue();
    }
  };

  connect() {
    this.offcanvasPartneredPharmacyIssue = new bootstrap.Offcanvas(
      this.offcanvasPartneredPharmacyIssueTarget,
    );

    document.addEventListener("keyup", this.escapeKeyupListener);
  }

  disconnect() {
    document.removeEventListener("keyup", this.escapeKeyupListener);
  }

  hideOffcanvasPartneredPharmacyIssue() {
    this.offcanvasPartneredPharmacyIssue.hide();
  }

  showOffcanvasPartneredPharmacyIssue() {
    this.offcanvasPartneredPharmacyIssue.show();
  }
}
