import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = ["addPaActionModal"];

  connect() {
    this.addPaActionModal = new bootstrap.Modal(this.addPaActionModalTarget);
  }

  displayModal() {
    this.addPaActionModal.show();
  }
}
