import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = [
    "editPaOutcomeModal",
    "outcome",
    "approvalFields",
    "approvalStartDate",
    "denialFields",
    "closureFields",
    "closureReasonSelection",
    "closureOtherReason",
    "closureOtherReasonInput",
  ];

  connect() {
    this.editPaOutcomeModal = new bootstrap.Modal(
      this.editPaOutcomeModalTarget,
    );

    this.displayOutcome();
  }

  displayModal() {
    this.editPaOutcomeModal.show();
  }

  displayOutcome() {
    const outcome = this.outcomeTarget.value;

    switch (outcome) {
      case "Approved":
        this.approvalFieldsTarget.hidden = false;
        this.approvalStartDateTarget.required = true;
        this.denialFieldsTarget.hidden = true;
        this.closureFieldsTarget.hidden = true;
        this.closureReasonSelectionTarget.required = false;
        break;
      case "Denied":
        this.approvalFieldsTarget.hidden = true;
        this.approvalStartDateTarget.required = false;
        this.denialFieldsTarget.hidden = false;
        this.closureFieldsTarget.hidden = true;
        this.closureReasonSelectionTarget.required = false;
        break;
      case "Closed":
        this.approvalFieldsTarget.hidden = true;
        this.approvalStartDateTarget.required = false;
        this.denialFieldsTarget.hidden = true;
        this.closureFieldsTarget.hidden = false;
        this.closureReasonSelectionTarget.required = true;
        break;
      default:
        this.approvalFieldsTarget.hidden = true;
        this.approvalStartDateTarget.required = false;
        this.denialFieldsTarget.hidden = true;
        this.closureFieldsTarget.hidden = true;
        this.closureReasonSelectionTarget.required = false;
        break;
    }
  }

  displayOtherCloseReason() {
    switch (this.closureReasonSelectionTarget.value) {
      case "Other":
        this.closureOtherReasonTarget.hidden = false;
        this.closureOtherReasonInputTarget.required = true;
        this.closureOtherReasonInputTarget.value = "";
        break;
      default:
        this.closureOtherReasonTarget.hidden = true;
        this.closureOtherReasonInputTarget.required = false;
        this.closureOtherReasonInputTarget.value = "";
        break;
    }
  }
}
