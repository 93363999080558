import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["messageField"];

  connect() {
    this.messageFieldTarget.focus();
  }

  clearMessageField() {
    this.messageFieldTarget.value = "";
  }
}
