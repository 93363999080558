import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "createMessageForm",
    "commentButton",
    "discardButton",
    "resolveButton",
    "messageField",
    "messageTypeButtons",
  ];

  connect() {
    this.messageFieldTarget.focus();
  }

  clearMessageField() {
    this.messageFieldTarget.value = "";
  }

  setMessageType(event) {
    const buttonLabel = event.target.innerText.trim();

    if (buttonLabel.includes("Discard")) {
      this.setDiscarding(buttonLabel);
    } else if (buttonLabel.includes("Resolve")) {
      this.setResolving(buttonLabel);
    } else {
      this.setCommenting(buttonLabel);
    }
  }

  setDiscarding(buttonLabel) {
    this.commentButtonTarget.classList.remove("active");
    this.discardButtonTarget.classList.add("active");
    this.resolveButtonTarget.classList.remove("active");
    this.createMessageFormTarget.action = this.createMessageFormTarget.action
      .replace("messages", "discards")
      .replace("resolutions", "discards");
    this.messageTypeButtonsTargets.forEach((button) => {
      button.value = buttonLabel;
      button.classList.remove("btn-primary");
      button.classList.remove("btn-success");
      button.classList.add("btn-danger");
      button.setAttribute("data-turbo-frame", "_top");
    });
  }

  setResolving(buttonLabel) {
    this.commentButtonTarget.classList.remove("active");
    this.discardButtonTarget.classList.remove("active");
    this.resolveButtonTarget.classList.add("active");
    this.createMessageFormTarget.action = this.createMessageFormTarget.action
      .replace("messages", "resolutions")
      .replace("discards", "resolutions");
    this.messageTypeButtonsTargets.forEach((button) => {
      button.value = buttonLabel;
      button.classList.remove("btn-primary");
      button.classList.add("btn-success");
      button.classList.remove("btn-danger");
      button.setAttribute("data-turbo-frame", "_top");
    });
  }

  setCommenting(buttonLabel) {
    this.commentButtonTarget.classList.add("active");
    this.discardButtonTarget.classList.remove("active");
    this.resolveButtonTarget.classList.remove("active");
    this.createMessageFormTarget.action = this.createMessageFormTarget.action
      .replace("resolutions", "messages")
      .replace("discards", "messages");
    this.messageTypeButtonsTargets.forEach((button) => {
      button.value = buttonLabel;
      button.classList.add("btn-primary");
      button.classList.remove("btn-success");
      button.classList.remove("btn-danger");
      button.removeAttribute("data-turbo-frame");
    });
  }
}
