import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
import TextareaAutogrow from "stimulus-textarea-autogrow";

import { application } from "./application";

const context = require.context("controllers", true, /\.js$/);

const contextComponents = require.context(
  "../../components",
  true,
  /_controller\.js$/,
);

application.load(
  definitionsFromContext(context).concat(
    definitionsFromContext(contextComponents),
  ),
);

application.register("textarea-autogrow", TextareaAutogrow);

application.debug = process.env.NODE_ENV === "development";

window.Stimulus = application;

export default application;
