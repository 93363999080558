import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = [
    "createPaOutcomeModal",
    "outcome",
    "approvalFields",
    "approvalStartDate",
    "denialFields",
    "closureFields",
    "closureReasonSelection",
    "closureOtherReason",
    "closureOtherReasonInput",
  ];

  connect() {
    this.createPaOutcomeModal = new bootstrap.Modal(
      this.createPaOutcomeModalTarget,
    );
  }

  displayModal() {
    this.createPaOutcomeModal.show();
  }

  displayOtherCloseReason() {
    this.closureOtherReasonTarget.hidden =
      this.closureReasonSelectionTarget.value !== "Other";
    this.closureOtherReasonInputTarget.required =
      this.closureReasonSelectionTarget.value === "Other";
  }

  displayOutcome() {
    const outcomeValue = this.outcomeTarget.value;

    this.approvalFieldsTarget.hidden = outcomeValue !== "Approved";
    this.approvalStartDateTarget.required = outcomeValue === "Approved";

    this.denialFieldsTarget.hidden = outcomeValue !== "Denied";

    this.closureFieldsTarget.hidden = outcomeValue !== "Closed";
    this.closureReasonSelectionTarget.required = outcomeValue === "Closed";
  }
}
