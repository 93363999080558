import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = ["processModal"];

  connect() {
    this.processModal = new bootstrap.Modal(this.processModalTarget);
  }

  disconnect() {
    // Cleans up the <body> side effects of opening a modal:
    this.hideModal();

    this.processModal.dispose();
  }

  showModal = () => {
    this.processModal.show();
  };

  hideModal = () => {
    this.processModal.hide();
  };
}
