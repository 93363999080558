import { Controller } from "@hotwired/stimulus";
import PaymentsUI from "healnow";

export default class extends Controller {
  static targets = [
    "submitButton",
    "nonce",
    "cardType",
    "cardLastFour",
    "methodType",
    "form",
  ];

  static values = {
    checkoutUrl: String,
    publicKey: String,
  };

  initialize() {
    this.card = this.setHealNowCard();
    this.loading = true;
    this.readyToTokenize = false;
  }

  connect() {
    this.card
      .mount("#save-card-form")
      .then(() => {
        this.setNotLoading();
      })
      .catch(() => {
        alert("An error occurred. Please try again.");
      });
  }

  setHealNowCard() {
    return PaymentsUI.card(this.checkoutUrlValue, this.publicKeyValue);
  }

  setLoading() {
    this.loading = true;
    this.setSubmitButtonDisabled();
  }

  setNotLoading() {
    this.loading = false;
    this.setSubmitButtonDisabled();
  }

  tokenize = () => {
    this.setLoading();

    this.card
      .tokenize()
      .then((token) => {
        this.nonceTarget.value = token;
        this.methodTypeTarget.value = "card";

        this.formTarget.requestSubmit();
      })
      .catch(() => {
        alert(
          "The payment information you entered wasn't able to be processed. Please try again or use a different payment method.",
        );

        this.setNotLoading();
      });
  };

  setSubmitButtonDisabled() {
    this.submitButtonTarget.disabled = this.loading;
  }
}
