import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = ["instructionSheetModal"];
  connect() {
    this.instructionSheetModel = new bootstrap.Modal(
      this.instructionSheetModalTarget,
    );
  }

  disconnect() {
    this.instructionSheetModel.dispose();
  }

  showInstructionSheetModal = () => {
    this.instructionSheetModel.show();
  };

  hideInstructionSheetModal = () => {
    this.instructionSheetModel.hide();
  };
}
