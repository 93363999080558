import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["nonce", "cardType", "cardLastFour", "methodType", "form"];

  submitForm = ({ detail: { nonce, methodType, cardType, lastFour } }) => {
    this.nonceTarget.value = nonce;
    this.methodTypeTarget.value = methodType;
    this.cardTypeTarget.value = cardType;
    this.cardLastFourTarget.value = lastFour;

    this.formTarget.requestSubmit();
  };
}
