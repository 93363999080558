import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = [
    "createNewPaModal",
    "identifierSourceSelection",
    "identifier",
    "coverMyMedsLabel",
    "genericLabel",
    "bankIdentificationNumber",
    "processorControlNumber",
    "groupNumber",
    "existingInsuranceSelection",
  ];

  connect() {
    this.createNewPaModal = new bootstrap.Modal(this.createNewPaModalTarget);
  }

  displayModal() {
    this.createNewPaModal.show();
  }

  assignIdentiferSourceLabel() {
    const selectedOption =
      this.identifierSourceSelectionTarget.options[
        this.identifierSourceSelectionTarget.selectedIndex
      ];

    this.identifierTarget.hidden =
      this.identifierSourceSelectionTarget.selectedIndex === 0;

    switch (selectedOption.value) {
      case "CoverMyMeds":
        this.coverMyMedsLabelTarget.hidden = false;
        this.genericLabelTarget.hidden = true;
        break;
      case "Gainwell":
        this.coverMyMedsLabelTarget.hidden = true;
        this.genericLabelTarget.hidden = false;
        break;
      default:
        this.coverMyMedsLabelTarget.hidden = true;
        this.genericLabelTarget.hidden = false;
    }
  }

  displayNewInsurance() {
    const selectedData =
      this.existingInsuranceSelectionTarget.selectedOptions[0].dataset;

    this.bankIdentificationNumberTarget.value = selectedData["planBin"] || "";
    this.processorControlNumberTarget.value = selectedData["planPcn"] || "";
    this.groupNumberTarget.value = selectedData["rxGroup"] || "";
  }
}
