import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = ["shipmentModal", "instructionSheetDownloadModal"];

  connect() {
    this.shipmentModal = new bootstrap.Modal(this.shipmentModalTarget);
    this.instructionSheetDownloadModal = new bootstrap.Modal(
      this.instructionSheetDownloadModalTarget,
    );
  }

  disconnect() {
    // Cleans up the <body> side effects of opening a modal:
    this.hideInstructionSheetDownloadModal();

    this.shipmentModal.dispose();
    this.instructionSheetDownloadModal.dispose();
  }

  showInstructionSheetDownloadModal = () => {
    this.instructionSheetDownloadModal.show();
  };

  showShipmentModal = () => {
    this.shipmentModal.show();
  };

  hideShipmentModal = () => {
    this.shipmentModal.hide();
  };

  hideInstructionSheetDownloadModal = () => {
    this.instructionSheetDownloadModal.hide();
  };
}
