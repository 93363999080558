import * as bootstrap from "bootstrap";

let tooltips;

const reattachTooltips = () => {
  if (tooltips) {
    tooltips.forEach((tooltip) => tooltip.dispose());
  }

  const tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]'),
  );

  tooltips = tooltipTriggerList.map(
    (tooltipTriggerEl) =>
      new bootstrap.Tooltip(tooltipTriggerEl, {
        delay: { show: 300, hide: 100 },
      }),
  );
};

document.addEventListener("turbo:load", reattachTooltips);

// This is a really, really dirty hack to suppprt tooltips replaced by Turbo
// Streams and async Turbo Frames. We should be using view_components that know
// how to manage their own event listeners instead of using global event
// listeners.
document.addEventListener("turbo:frame-load", () => {
  setTimeout(reattachTooltips, 150);
});

document.addEventListener("turbo:before-stream-render", () => {
  setTimeout(reattachTooltips, 150);
});
