import { Controller } from "@hotwired/stimulus";

const RESET_COOLDOWN_MS = 2000;

export default class extends Controller {
  static targets = ["iconWrap"];

  static values = {
    str: String,
  };

  connect() {
    this.resetTimer = null;
  }

  copy() {
    navigator.clipboard.writeText(this.strValue);

    if (this.resetTimer) {
      clearTimeout(this.resetTimer);
    }

    this.setIconCopied();
    this.resetTimer = setTimeout(this.reset.bind(this), RESET_COOLDOWN_MS);
  }

  reset() {
    this.resetTimer = null;
    this.setIconCopy();
  }

  setIconCopied() {
    this.iconWrapTarget.innerHTML = '<i class="fas fa-check"></i>';
  }

  setIconCopy() {
    this.iconWrapTarget.innerHTML = '<i class="fas fa-copy"></i>';
  }
}
