import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    disableUntil: String,
  };

  static targets = ["button"];

  connect() {
    this.originalLabel = this.buttonTarget.textContent;
    this.disableUntilDate = new Date(this.disableUntilValue);
    this.updateButtonState();
  }

  disconnect() {
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
  }

  updateButtonState() {
    const now = new Date();

    if (now < this.disableUntilDate) {
      this.disableButton();
      this.updateCountdown();
      this.startCountdown();
    } else {
      this.enableButton();
    }
  }

  disableButton() {
    this.buttonTarget.disabled = true;
  }

  enableButton() {
    this.buttonTarget.disabled = false;
    this.buttonTarget.textContent = this.originalLabel;
  }

  updateCountdown() {
    const now = new Date();
    const secondsLeft = Math.max(
      0,
      Math.ceil((this.disableUntilDate - now) / 1000),
    );

    if (secondsLeft > 0) {
      this.buttonTarget.textContent = `${this.originalLabel} (In ${secondsLeft}s)`;
    } else {
      this.enableButton();
      clearInterval(this.countdownInterval);
    }
  }

  startCountdown() {
    clearInterval(this.countdownInterval);
    this.countdownInterval = setInterval(() => this.updateCountdown(), 1000);
  }
}
