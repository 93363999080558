import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

const SHOW_ALERT_FOR_MS = 15000;

export default class extends Controller {
  static targets = ["alertToast"];

  connect() {
    document.addEventListener("turbo:submit-end", this.handleSubmitEndEvent);
    document.addEventListener(
      "turbo:fetch-request-error",
      this.handleFetchRequestErrorEvent,
    );

    this.alertToast = new bootstrap.Toast(this.alertToastTarget, {
      delay: SHOW_ALERT_FOR_MS,
    });

    this.alertToastTarget.addEventListener(
      "show.bs.toast",
      this.handleToastShow,
    );
    this.alertToastTarget.addEventListener(
      "hide.bs.toast",
      this.handleToastHide,
    );
  }

  disconnect() {
    document.removeEventListener("turbo:submit-end", this.handleSubmitEndEvent);
    document.removeEventListener(
      "turbo:fetch-request-error",
      this.handleFetchRequestErrorEvent,
    );

    this.alertToastTarget.removeEventListener(
      "show.bs.toast",
      this.handleToastShow,
    );
    this.alertToastTarget.removeEventListener(
      "hide.bs.toast",
      this.handleToastHide,
    );

    this.alertToast.dispose();
  }

  handleToastShow = () => {
    this.alertToastTarget.setAttribute("aria-live", "assertive");
    this.alertToastTarget.setAttribute("aria-hidden", "false");
  };

  handleToastHide = () => {
    this.alertToastTarget.setAttribute("aria-live", "off");
    this.alertToastTarget.setAttribute("aria-hidden", "true");
  };

  handleSubmitEndEvent = (event) => {
    if (event?.detail?.success) return;

    const status = event?.detail?.fetchResponse?.response?.status;
    if (!status) return;

    if (status >= 500) {
      this.alertToast.show();
    }
  };

  handleFetchRequestErrorEvent = () => {
    this.alertToast.show();
  };
}
