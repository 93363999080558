import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    document.documentElement.addEventListener(
      "turbo:submit-start",
      this.disableButton,
    );
    document.documentElement.addEventListener(
      "turbo:submit-end",
      this.enableButton,
    );
  }

  disconnect() {
    document.documentElement.removeEventListener(
      "turbo:submit-start",
      this.disableButton,
    );
    document.documentElement.removeEventListener(
      "turbo:submit-end",
      this.enableButton,
    );
  }

  disableButton = () => {
    this.element.disabled = true;
  };

  enableButton = () => {
    this.element.disabled = false;
  };
}
