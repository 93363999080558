import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  disconnect() {
    window.zEACLoaded = undefined;
  }

  open() {
    if (window.zE) {
      window.zE("messenger", "open");
    }
  }
}
