import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["allergiesList", "allergyCheckbox"];

  static values = {
    selectedAllergies: Array,
    emptyText: String,
    selectedLabelText: String,
  };

  connect() {
    this.setSelectedAllergiesValue();
  }

  handleCheckBox() {
    this.setSelectedAllergiesValue();
  }

  setSelectedAllergiesValue() {
    this.selectedAllergiesValue = this.allergyCheckboxTargets
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => checkbox.value);
  }

  selectedAllergiesValueChanged() {
    this.allergiesListTarget.innerHTML = this.generateCurrentAllergiesList();
  }

  generateCurrentAllergiesList() {
    if (this.selectedAllergiesValue.length > 0) {
      return `<strong>${this.selectedLabelTextValue}:</strong>
        <ul class='list-unstyled ps-2'>
          ${this.selectedAllergiesValue
            .map((allergyName) => `<li>${allergyName}</li>`)
            .join("")}
        </ul>`;
    }

    return this.emptyTextValue;
  }
}
