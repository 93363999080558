import { Controller } from "@hotwired/stimulus";
import braintree from "braintree-web";
import * as Sentry from "@sentry/browser";

export default class extends Controller {
  static targets = [
    "cardNumber",
    "cardExp",
    "cardCvv",
    "postalCode",
    "submitButton",
  ];

  static values = {
    tokenizationKey: String,
  };

  initialize() {
    this.hostedFieldsInstance = null;

    this.readyToTokenize = false;
    this.loading = true;
  }

  connect() {
    this.createBraintreeClient()
      .then(this.createHostedFieldsInstance)
      .then((hostedFieldsInstance) => {
        hostedFieldsInstance.on("validityChange", this.handleValidityChange);
        hostedFieldsInstance.on("inputSubmitRequest", this.tokenize);

        this.hostedFieldsInstance = hostedFieldsInstance;

        this.setNotLoading();
      })
      .catch((e) => {
        Sentry.captureException(e);

        alert("An error occurred. Please try again.");
      });
  }

  disconnect() {
    if (this.hostedFieldsInstance) {
      this.hostedFieldsInstance.teardown();
    }
  }

  setLoading() {
    this.loading = true;
    this.setSubmitButtonDisabled();
  }

  setNotLoading() {
    this.loading = false;
    this.setSubmitButtonDisabled();
  }

  setSubmitButtonDisabled() {
    this.submitButtonTarget.disabled = this.loading || !this.readyToTokenize;
  }

  tokenize = () => {
    if (!this.readyToTokenize) return;

    this.setLoading();

    this.hostedFieldsInstance
      .tokenize()
      .then((result) => {
        if (!result?.nonce) {
          // TODO: Handle error more gracefully
          alert("An error occurred. Please try again.");

          this.setNotLoading();
          return;
        }

        const {
          nonce,
          type: methodType,
          details: { cardType, lastFour },
        } = result;

        this.dispatch("creditCardTokenized", {
          detail: { nonce, methodType, cardType, lastFour },
        });
      })
      .catch(() => {
        // TODO: Handle error more gracefully
        alert("An error occurred. Please try again.");

        this.setNotLoading();
      });
  };

  handleValidityChange = (event) => {
    this.readyToTokenize = [
      event.fields.cvv,
      event.fields.expirationDate,
      event.fields.number,
      event.fields.postalCode,
    ].every((field) => field.isValid);

    this.setSubmitButtonDisabled();
  };

  createHostedFieldsInstance = (clientInstance) =>
    braintree.hostedFields.create({
      client: clientInstance,
      styles: {
        input: {
          "font-size": "16px",
        },
      },
      fields: {
        number: {
          container: this.cardNumberTarget,
          placeholder: "4111 1111 1111 1111",
        },
        expirationDate: {
          container: this.cardExpTarget,
          placeholder: "MM/YYYY",
        },
        cvv: {
          container: this.cardCvvTarget,
          placeholder: "123",
        },
        postalCode: {
          container: this.postalCodeTarget,
          placeholder: "11111",
        },
      },
    });

  createBraintreeClient() {
    return braintree.client.create({
      authorization: this.tokenizationKeyValue,
    });
  }
}
