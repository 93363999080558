import * as bootstrap from "bootstrap";

let popovers;

const reattachPopovers = () => {
  if (popovers) {
    popovers.forEach((popover) => popover.dispose());
  }

  const popoverTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="popover"]'),
  );

  popovers = popoverTriggerList.map(
    (popoverTriggerEl) => new bootstrap.Popover(popoverTriggerEl),
  );
};

document.addEventListener("turbo:load", reattachPopovers);

// This is a really, really dirty hack to suppprt popovers replaced by Turbo
// Streams and async Turbo Frames. We should be using view_components that know
// how to manage their own event listeners instead of using global event
// listeners.
document.addEventListener("turbo:frame-load", () => {
  setTimeout(reattachPopovers, 150);
});

document.addEventListener("turbo:before-stream-render", () => {
  setTimeout(reattachPopovers, 150);
});
